<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper mt-4">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-12">
            <div class="card p-md-2 p-0">
              <div class="card-body pt-3 pb-5">
                <div class="row justify-content-center">
                  <div class="col-xl-8">
                    <h4 class="mb-3">Technician Working Schedule Settings</h4>
                    <div  class="d-flex w-100 align-items-end mb-2">
                      <div class="w-100 mr-1">
                        <label for="">Start Time</label>
                        <input type="text" class="form-control" placeholder="" v-model="technicianWorkingSchedule.value.start" >
                      </div>
                      <div class="w-100 mr-1">
                        <label for="">End Time</label>
                        <input type="text" class="form-control" placeholder="" v-model="technicianWorkingSchedule.value.end">
                      </div>
                    </div>
                    <div class="text-right">
                      <button class="btn btn-primary px-lg-4 px-2" type="submit" @click="updateTechnicianWorkingSchedule">Update</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
// appLayout
import AppLayout from '../../../../layouts/backEnd/AppLayout.vue'

// mixins
import ShowToastMessage from '@/components/backEnd/mixins/ShowToastMessage';
import Loader from '@/components/backEnd/mixins/Loader';
import Authorization from '@/components/backEnd/mixins/Authorization';
// core packages
import {
  mapActions, mapGetters,
} from "vuex";

export default {
  name: 'settingsCustomer',
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
  },
  data() {
    return {
      getSettingsParams: {
        type: ['technician'],
      },
      technicianWorkingSchedule:
          {
            id: '',
            key:'',
            type:'technician',
            value:{}
          }
      ,
    };
  },

  computed: {
    ...mapGetters({
      settingTechnicianWorkingSchedule:"appSettings/settingTechnicianWorkingSchedule",
    }),
  },

  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
      putSetting: "appSettings/putSetting",
    }),

    async getSettingList() {
      this.loader(true);
      const response = await this.getSettings(this.getSettingsParams);
      this.loader(false);
      if (response.status === 200){
        this.technicianWorkingSchedule = this.settingTechnicianWorkingSchedule
        return;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
    },

    technicianWorkingScheduleValidationChecker(){
      let isNull = false;

      if(this.technicianWorkingSchedule.value.start === null || this.technicianWorkingSchedule.value.start === ""){
        isNull = true;
      }
      if(this.technicianWorkingSchedule.value.end === null || this.technicianWorkingSchedule.value.end === ""){
        isNull = true;
      }
      if(isNull){
        this.showToastMessage({
          type:'error',
          message:`Technician working schedule can't be empty`
        });
        return isNull;
      }
    },

    async updateTechnicianWorkingSchedule() {
      if (!this.technicianWorkingScheduleValidationChecker()){
        const data = {
          type: this.technicianWorkingSchedule.type,
          key: this.technicianWorkingSchedule.key,
          value: this.technicianWorkingSchedule.value,
        }

        const dataObj = {
          id: this.technicianWorkingSchedule.id,
          data: data
        }

        this.loader(true);
        const response = await this.putSetting(dataObj);
        this.loader(false);
        if (response.status === 200 ) {

          this.showToastMessage({
            type:'success',
            message:`  Updated Successfully  `
          });
          return;
        }

        if (response.message) {
          this.showToastMessage(response);
        }

      }
    }
  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    this.loader(false);
  },
}
</script>

<style scoped>
</style>